import { DealPostType } from '@types';

export function getRandomAnimationNumber(): number {
  return Math.floor(Math.random() * (45000 - 30000 + 1)) + 30000;
}

export interface ProductCarouselProps {
  products: DealPostType[];
  title?: string;
  tag?: string;
  onClick?: (link: string) => void;
  afterChange?: (slideNumber: number) => void;
  seeAllClick?: () => void;
  seeAllButtonText?: string;
  seeAllText?: string;
  loading?: boolean;
  lazyLoad?: boolean;
  shouldAnimate?: boolean;
  showSeeAllCard?: boolean;
  showKeenSlider?: boolean;
}
