import { ElementType } from 'react';
import {
  Card,
  TypographyProps as MuiTypographyProps,
  ButtonBase,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';

export const StyledCard = styled(Card)(() => ({
  margin: '8px',
  height: 'calc(100% - 16px)', // Set height to 100% to make all cards the same height
  '&:hover': {
    cursor: 'pointer'
  },
  position: 'relative'
}));

export const LinkButton = styled(ButtonBase)({
  display: 'block',
  textAlign: 'initial',
  width: '100%'
});

interface TypographyProps extends MuiTypographyProps {
  component?: ElementType<any>;
}

export const Title = styled(Typography)<TypographyProps>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 14px;
`;
