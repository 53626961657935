import React from 'react';
import { CardContent, Typography, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import config from '@configFile';
import { CarouselRibbon } from '@components/Ribbon';
import {
  formatPrice,
  getQtyString,
  shouldShowFinalPrice,
  shouldShowListPrice,
  parseTitle,
  getProductLink
} from '@utils/index';
import CouponChip from '@components/CouponChip';
import SubscribeSaveChip from '@components/SubscribeSaveChip';
import NewTooltip from '@components/NewTooltip';
import { DealPostType } from '@types';
import AdminActions from '@components/AdminActions';
import { StyledCard, Title, LinkButton } from './utils';

interface DealCardProps extends DealPostType {
  tag?: string;
  onClick?: (link: string) => void;
  sx?: any;
  className?: string;
}

const ProductCarouselCard = (props: DealCardProps) => {
  const {
    title,
    listPrice,
    finalPrice,
    couponFixed,
    maxSs,
    image,
    image240Url,
    ASIN,
    couponPercentage,
    tag = config.AFFILIATE_TAGS.MOST_POPULAR,
    qty,
    qtyTerm,
    onClick = () => {},
    sx = null,
    className = null
  } = props;

  const link = getProductLink(ASIN, tag);

  const cardOnClick = (e: React.MouseEvent<any>) => {
    window.open(link, '_blank');
    e.stopPropagation();
    onClick(link);
  };

  const showListPrice = shouldShowListPrice({ listPrice, finalPrice });
  const showFinalPrice = shouldShowFinalPrice({ listPrice, finalPrice });
  const qtyString = getQtyString({ qty, qtyTerm, finalPrice });

  return (
    <Box sx={sx} className={className || undefined}>
      <StyledCard onClick={cardOnClick}>
        <CarouselRibbon
          dealSummary={props}
          opacity={1}
          fontSize="11px"
          padding="3px 8px"
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1
          }}
          // catch the click event so it doesn't bubble up to the card
          onClick={(e) => e.stopPropagation()}
        >
          <AdminActions ASIN={ASIN} deal={props} />
        </Box>
        <LinkButton
          onClick={cardOnClick}
          sx={{
            marginTop: '12px'
          }}
        >
          <Box
            sx={{
              height: 140,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
          >
            <Box
              component="img"
              alt={parseTitle(title)}
              src={image240Url?.replace('.jpg', '.webp') || image}
              sx={{
                objectFit: 'cover',
                maxHeight: '100%',
                objectPosition: 'center'
              }}
            />
          </Box>
        </LinkButton>
        <CardContent
          sx={{
            padding: '12px',
            '@media (max-width: 530px)': {
              p: 1
            },
            // account for the icon button
            paddingBottom: '12px !important'
          }}
        >
          <NewTooltip deal={props} />
          <Tooltip title={parseTitle(title)} placement="top">
            <Title
              gutterBottom
              variant="h5"
              component="h4"
              sx={{
                fontSize: '12px'
              }}
            >
              {parseTitle(title)}
            </Title>
          </Tooltip>
          {couponFixed || (couponPercentage && couponPercentage > 0) ? (
            <CouponChip {...props} showCouponText={false} />
          ) : null}
          {maxSs ? <SubscribeSaveChip {...props} /> : null}
          {showListPrice && listPrice > 0 && listPrice !== finalPrice ? (
            <Typography
              variant="body2"
              style={{
                marginTop: '4px',
                maxWidth: '90%'
              }}
              color="text.secondary"
            >
              List Price:{' '}
              <Typography
                variant="body2"
                component="span"
                sx={{
                  textDecoration: 'line-through',
                  display: 'inline'
                }}
              >
                {formatPrice(listPrice)}
              </Typography>
            </Typography>
          ) : null}
          {showFinalPrice && (
            <Typography
              variant="body2"
              color="text.primary"
              style={{
                marginTop: showListPrice ? '2px' : '4px',
                fontWeight: 'bold'
              }}
            >
              {maxSs ? 'Lowest Price: ' : 'Final Price: '}{' '}
              {formatPrice(finalPrice)}
              {qtyString}
            </Typography>
          )}
          {!showFinalPrice && (
            <Typography
              variant="body2"
              color="text.primary"
              sx={{
                fontWeight: 'bold',
                mt: 1,
                fontSize: '12px'
              }}
            >
              Click to see price on Amazon
            </Typography>
          )}
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default ProductCarouselCard;
